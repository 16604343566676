import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// Helper function to lighten HSL color
export const lightenHsl = (hslString, percentage) => {
  if (!hslString) {
    return;
  }

  const match = hslString.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
  if (!match) {
    throw new Error(`Invalid HSL string: ${hslString}`);
  }

  const [_, h, s, l] = match;
  const lightness = parseInt(l, 10);
  if (isNaN(lightness)) {
    throw new Error(`Invalid lightness: ${l}`);
  }

  const increase = (100 - lightness) * (percentage / 100);
  return `hsl(${h}, ${s}%, ${lightness + increase}%)`;
};

// Helper function to calculate the centroid of a polygon
const calculatePolygonCentroid = (points) => {
  const parsedPoints = points.split(" ").map((point) => point.split(",").map(Number));
  const n = parsedPoints.length;

  // Calculate the initial centroid
  const sumX = parsedPoints.reduce((acc, [x]) => acc + x, 0);
  const centerX = sumX / n;

  // Sort points by their X distance to the center
  const sortedPoints = parsedPoints.sort(
    ([x1], [x2]) => Math.abs(x1 - centerX) - Math.abs(x2 - centerX)
  );

  // Select the closest 20% of the points, with a minimum of 2 points
  const numPointsToSelect = Math.max(2, Math.ceil(n * 0.2));
  const top20Percent = sortedPoints.slice(0, numPointsToSelect);

  // Calculate the average Y position of the selected points
  const avgY = top20Percent.reduce((acc, [, y]) => acc + y, 0) / top20Percent.length;

  return { x: centerX, y: avgY };
};

function ImageZoneMapper({ options, zones, selectedZone, handleZoneClick }) {
  const tickets = useSelector((state) => state.ticketStateExperimental.tickets);

  const renderGradients = useMemo(() => {
    return Object.entries(zones).map(([zoneName, zoneData], index) => {
      const { amount, max_amount, color } = zoneData;

      const remainingSeatsPercentage = Math.floor((amount / max_amount) * 100 - 1) || 0;
      const gradientId = `gradient-${index}`;
      return (
        <linearGradient id={gradientId} key={gradientId} gradientTransform="rotate(90)">
          <stop offset="0%" stopColor={lightenHsl(color, 40)} />
          <stop offset={`${remainingSeatsPercentage - 5}%`} stopColor={lightenHsl(color, 80)} />
          <stop offset={`${remainingSeatsPercentage}%`} stopColor={color} />
          <stop offset="100%" stopColor={lightenHsl(color, 40)} />
        </linearGradient>
      );
    });
  }, [zones]);

  const renderZones = useMemo(() => {
    return Object.entries(zones).map(([zoneName, zoneData], index) => {
      const { amount, on_sale, color, location } = zoneData;

      if (!location) return;

      const isSelected = zoneName === selectedZone;

      const isAvailable = amount > 0 && on_sale;
      let zoneFillColor = "hsl(0, 0%, 95%)";
      if (isAvailable) zoneFillColor = `url(#gradient-${index})`;

      let strokeColor = "hsl(0, 0%, 60%)";
      if (isAvailable) strokeColor = lightenHsl(color, -10);
      if (isSelected) strokeColor = lightenHsl(color, -30);
      let opacity = 0.35;
      if (isAvailable) opacity = 0.6;
      const strokeDasharray = isSelected ? "" : "14 6";

      const shapeProps = {
        id: zoneName,
        fill: zoneFillColor,
        stroke: strokeColor,
        cursor: on_sale ? "pointer" : "not-allowed",
        strokeWidth: isSelected ? "10" : "6",
        opacity: opacity,
        strokeDasharray,
        strokeLinejoin: "round",
      };

      let centerX, centerY, zoneWidth;

      if (location.shape === "rect") {
        centerX = 15 + Number(location.position.x) + Number(location.size.width) / 2;
        centerY = 15 + Number(location.position.y) + Number(location.size.height) / 2;
        zoneWidth = Number(location.size.width);
      } else if (location.shape === "polygon") {
        const centroid = calculatePolygonCentroid(location.points);
        centerX = centroid.x + 15;
        centerY = centroid.y + 10;
        zoneWidth =
          Math.max(...location.points.split(" ").map((point) => parseFloat(point.split(",")[0]))) -
          Math.min(...location.points.split(" ").map((point) => parseFloat(point.split(",")[0])));
      }

      const zoneTickets = tickets.filter((ticket) => ticket.category === zoneName);

      const rows = [];
      const rowCapacity = Math.floor(zoneWidth / 40);
      for (let i = 0; i < zoneTickets.length; i += rowCapacity) {
        rows.push(zoneTickets.slice(i, i + rowCapacity));
      }

      return (
        <g key={zoneName}>
          {location.shape === "rect" && (
            <rect
              {...shapeProps}
              width={location.size.width}
              height={location.size.height}
              x={location.position.x}
              y={location.position.y}
              onClick={on_sale ? () => handleZoneClick(zoneName) : undefined}
            />
          )}
          {location.shape === "polygon" && (
            <polygon
              {...shapeProps}
              points={location.points}
              onClick={on_sale ? () => handleZoneClick(zoneName) : undefined}
            />
          )}
          {rows.map((row, rowIndex) => {
            const yOffset = centerY - rows.length * 20 + rowIndex * 50;

            return (
              <g key={`row-${rowIndex}-${zoneName}`}>
                {row.map((ticket, i) => {
                  const xOffset = centerX - row.length * 20 + i * 50;
                  const textColor = lightenHsl(color, -10);

                  return (
                    <g
                      key={`ticket-${ticket.id}`}
                      onClick={on_sale ? () => handleZoneClick(zoneName) : undefined}
                      cursor={on_sale ? "pointer" : "not-allowed"}
                    >
                      <circle
                        cx={xOffset}
                        cy={yOffset}
                        r="20"
                        fill={lightenHsl(color, 70)}
                        stroke={lightenHsl(color, -10)}
                        strokeWidth="6"
                      />
                      <text
                        x={xOffset}
                        y={yOffset + 2}
                        fill={textColor}
                        fontSize="28px"
                        fontWeight="800"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fontFamily="monospace"
                      >
                        {ticket.id}
                      </text>
                    </g>
                  );
                })}
              </g>
            );
          })}
        </g>
      );
    });
  }, [zones, tickets, handleZoneClick]);

  return (
    <svg
      version="1.1"
      className="buy-img-mapper"
      xmlns="http://www.w3.org/2000/svg"
      width={options.width || 2000}
      height={options.height || 2000}
      viewBox={`0, 0, ${options.width || 2000}, ${options.height || 2000}`}
      preserveAspectRatio="xMinYMin"
    >
      <defs>{renderGradients}</defs>
      {renderZones}
    </svg>
  );
}

export default ImageZoneMapper;

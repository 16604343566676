import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./userSlice";
import { entranceControllerReducer } from "./entranceControllerSlice";
import { loginReducer } from "./loginSlice";
import { ticketStateExperimental } from "./ticket-slice";
import languageSlice from "./language-slice";
import exchangeRatesSlice from "./exchange-rates-slice";
import { resellerState } from "./reseller-slice";

export const store = configureStore({
  reducer: {
    userState: userReducer,
    resellerState: resellerState,
    ticketStateExperimental: ticketStateExperimental,
    entranceControllerState: entranceControllerReducer,
    loginState: loginReducer,
    language: languageSlice,
    exchangeRates: exchangeRatesSlice,
  },
});

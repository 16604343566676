import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/style.css";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { ScrollToTop } from "./components/helper/ScrollToTop";
import { store } from "./store/store";
import { I18nextProvider } from "react-i18next";
import LanguageUpdater from "./components/LanguageUpdater";
import { ToastContainer } from "react-toastify";

// Fix for Promise.allSettled because it is not natively supported for some reason...
if (typeof Promise.allSettled !== "function") {
  Promise.allSettled = function (promises) {
    return Promise.all(
      promises.map((p) =>
        Promise.resolve(p).then(
          (value) => ({ status: "fulfilled", value }),
          (reason) => ({ status: "rejected", reason })
        )
      )
    );
  };
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer stacked />
    <Router>
      <I18nextProvider>
        <LanguageUpdater />
        <ScrollToTop />
        <App />
      </I18nextProvider>
    </Router>
  </Provider>
);

import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import LandingWithSEO from "../pages/landing/Landing";
import SinglePage from "../pages/single-page/SinglePage";
import { ProtectedRoute } from "./ProtectedRoutes";
import { ThankYou } from "../pages/payment-result-page/ThankYou";
import { Failed } from "../pages/payment-result-page/Failed";
import { Verification } from "../auth/Verification";
import { QRscanner } from "../pages/entrance-controller/QRscanner";
import { ProtectedControllerRoute } from "./ProtectedControllerRoute";
import { EntranceControllerLogin } from "../pages/entrance-controller/EntranceControllerLogin";
import { ListPage } from "../pages/list-page/ListPage";
import { OverallInfo } from "../pages/overall-info-pages/OverallInfo";
import { ErrorPage } from "../components/ErrorPage";
import { DrawPlace } from "../pages/draw-place/DrawPlace";
import { PasswordReset } from "../auth/PasswordReset";
import { OrderConfirmation } from "../pages/order_confirmation/OrderConfirmation";
import BuyPage from "../pages/buy/BuyPage";
import Loading from "./Loading";

// Lazy load Profile component
const Profile = React.lazy(() => import("../pages/profile-page/Profile"));

export const AllRoutes = () => {
  return (
    <div className="app-body">
      <Routes>
        <Route exact path="/" element={<LandingWithSEO />} />
        <Route path="/single/:id" element={<SinglePage />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            </ProtectedRoute>
          }
        />
        <Route path="/buy/:eventId" element={<BuyPage />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/failed" element={<Failed />} />
        <Route path="/verify/:verificationCode" element={<Verification />} />
        <Route path="/reset_password/:requestNumber" element={<PasswordReset />} />
        <Route path="/informations" element={<OverallInfo />} />
        <Route path="/order_confirmation" element={<OrderConfirmation />} />
        <Route
          path="/draw"
          element={
            <ProtectedRoute>
              <DrawPlace />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <ErrorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/qr_scanner"
          element={
            <ProtectedControllerRoute>
              <QRscanner />
            </ProtectedControllerRoute>
          }
        />
        <Route path="/controller_login" element={<EntranceControllerLogin />} />
        <Route path="/list_page" element={<ListPage />} />
      </Routes>
    </div>
  );
};

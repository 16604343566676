import "devextreme/dist/css/dx.light.css";
import { Footer, Navbar } from "./components";
import { AllRoutes } from "./routes/AllRoutes";
import { useDispatch } from "react-redux";
import { setToken, setUserID } from "./store/userSlice";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { setLanguage } from "./store/language-slice";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    const fullURL = window.location.href;

    // Send the pageview event to Google Analytics with the full URL
    ReactGA.send({ hitType: "pageview", type: location.pathname, page: fullURL });
  }, [location.pathname]);

  let userId;
  let token;
  let language;

  if (localStorage.getItem("userId")) {
    userId = localStorage.getItem("userId");
    token = localStorage.getItem("token");
    language = localStorage.getItem("language");
  } else if (sessionStorage.getItem("userId")) {
    userId = sessionStorage.getItem("userId");
    token = sessionStorage.getItem("token");
    language = localStorage.getItem("language");
  }

  if (userId) {
    dispatch(setUserID(userId));
    dispatch(setToken(token));
    dispatch(setLanguage(language || "hr"));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const unwantedElement = document.getElementById("my-app");
      if (unwantedElement) {
        unwantedElement.remove();
      }
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dotted-background">
      <Navbar />
      <AllRoutes />
      <Footer />
    </div>
  );
}

export default App;
